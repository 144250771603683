<template>
   <v-layout align-start justify-center>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="4000"
      >
         {{ snackText }}
         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <v-dialog v-model="dialogCiclo" persistent max-width="500px">
         <v-form ref="formCiclos" lazy-validation>
            <v-card>
               <v-card-text class="text--primary" style="margin-top: -20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <span class="text-h5 font-weight-medium"
                              >Agregar Ciclo</span
                           >
                        </v-flex>
                     </v-layout>
                     <v-layout wrap>
                        <v-flex xs12 sm6>
                           <v-text-field
                              v-model.trim="nombre"
                              v-mask="getCicloMask"
                              :rules="rulesNombre"
                              color="primary"
                              label="Nombre"
                           ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                           <v-text-field
                              v-model.number="annio"
                              v-mask="annioMask"
                              :rules="rulesAnnio"
                              color="primary"
                              label="Año"
                           ></v-text-field>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions
                  style="
                     margin-top: -40px;
                     margin-right: 20px;
                     padding-bottom: 20px;
                  "
               >
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close">Cancelar</v-btn>
                  <v-btn
                     color="primary white--text"
                     :disabled="!nombre || !annio"
                     @click="guardarCiclo"
                     >Guardar</v-btn
                  >
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <v-card elevation="0" width="600px" class="my-1">
         <v-toolbar color="primary" dark>
            <v-toolbar-title style="font-weight: 500">Ciclos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
               ref="txtBusqueda"
               v-model="search"
               class="mt-3"
               color="white"
               append-icon="fa-search"
               label="Búsqueda"
               single-line
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom max-width="300" color="primary">
               <template #activator="{ on }">
                  <v-btn
                     text
                     icon
                     color="white darken-3"
                     v-on="on"
                     @click="dialogCiclo = true"
                  >
                     <v-icon>fas fa-plus</v-icon>
                  </v-btn>
               </template>
               <span style="font-size: 15px"> Agregar Ciclo </span>
            </v-tooltip>
         </v-toolbar>

         <v-data-table
            :headers="headers"
            :items="ciclos"
            :loading="loadingCiclos"
            loading-text="Listando ciclos, por favor espere..."
            no-data-text="No se encontraron ciclos..."
            :search="search"
            :footer-props="{
               itemsPerPageOptions: [10, 25, 50],
               itemsPerPageText: 'Filas por página:'
            }"
            no-results-text="Búsqueda sin resultados"
            class="elevation-1"
         >
            <template slot="no-data">
               <div>
                  <v-container>
                     <v-layout wrap align-center>
                        <v-flex xs12 class="text-center">
                           <v-btn color="primary" @click="listarCiclos"
                              >Resetear</v-btn
                           >
                        </v-flex>
                     </v-layout>
                  </v-container>
               </div>
            </template>
         </v-data-table>
      </v-card>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'Ciclos',
   directives: {
      mask
   },
   data: () => ({
      headers: [
         {
            text: 'Nombre',
            value: 'nombre',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Año',
            value: 'annio',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Ciclo',
            value: 'numero',
            align: 'center',
            sortable: false,
            class: 'titulo'
         }
      ],

      nombre: '',
      annio: null,
      annioMask: '####',
      rulesNombre: [(v) => !!v || !!v.trim() || 'Ingrese un nombre'],
      rulesAnnio: [
         (v) => !!v || 'Ingrese el año',
         (v) =>
            (Number.isInteger(Number(v)) && v > 0) ||
            'El año debe ser un número mayor a 0'
      ],

      snackText: '',
      snackbar: false,
      snackColor: 'primary',

      ciclos: [],
      loadingCiclos: false,
      dialogCiclo: false,
      search: ''
   }),
   computed: {
      ...authComputed,
      getCicloMask() {
         if (!this.nombre) return {}

         let mask = 'I-####'

         if (this.nombre[1] && this.nombre[1].toLocaleUpperCase() == 'I')
            mask = 'II-####'

         // Armando Objeto para Custom v-mask
         // Formatos Aceptados: I-9999, II-9999
         return {
            mask: mask,
            tokens: {
               I: {
                  pattern: /[I||i]/,
                  transform: (v) => v.toLocaleUpperCase()
               },
               '#': { pattern: /\d/ }
            }
         }
      }
   },
   created() {
      if (this.oidcUser.role != 'admin') {
         this.$router.push({ name: 'inicio' })
      } else {
         this.listarCiclos()
      }
   },
   methods: {
      listarCiclos() {
         this.loadingCiclos = true

         axios
            .get('/api/Ciclos')
            .then((res) => (this.ciclos = res.data))
            .catch((err) => console.error(err))
            .finally(() => (this.loadingCiclos = false))
      },
      guardarCiclo() {
         if (this.$refs.formCiclos.validate()) {
            const cicloData = {
               nombre: this.nombre,
               annio: this.annio
            }

            axios
               .post('/api/Ciclos', cicloData)
               .then(() => {
                  this.snackText = 'El ciclo fue agregado exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true

                  this.close()
                  this.listarCiclos()
               })
               .catch((err) => {
                  this.snackText = 'No se pudo agregar el ciclo!'
                  this.snackColor = 'snackError'
                  this.snackbar = true

                  console.error(err)
               })
         } else {
            this.snackText = 'Los campos son requeridos!'
            this.snackColor = 'snackError'
            this.snackbar = true
         }
      },
      close() {
         if (this.$refs.formCiclos) this.$refs.formCiclos.resetValidation()

         this.dialogCiclo = false
         this.nombre = ''
         this.annio = null
      }
   }
}
</script>
